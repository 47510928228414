import { Injectable } from "@angular/core";
import { IPaginatedData } from '../../shared/model/paginated-data.model';
import { UserInfoRepository } from "../repository/user-info.repository";
import { IUserInfoSearchFilter } from "../../shared/model/user-info-search-filter.model";
import { IUserInfo } from "../../shared/model/userInfo.model";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { updateUserInfoAction } from "../store/auth/auth.actions";

@Injectable({ providedIn: 'root' })
export class UserInfoService {

  constructor(private userRepo: UserInfoRepository, private store: Store) {
  }

  public getUser(id: string): Observable<IUserInfo> {
    return this.userRepo.getUser(id);
  }

  public findUsers(searchFilter: IUserInfoSearchFilter): Observable<IPaginatedData<IUserInfo>> {
    return this.userRepo.findUsers(searchFilter);
  }

  public updateUser(user: IUserInfo): Observable<IUserInfo> {
    return this.userRepo.updateUser(user);
  }

  public updateCurrentUserInfo(userInfo: IUserInfo) {
    this.store.dispatch(updateUserInfoAction({ data: userInfo }));
  }
}
